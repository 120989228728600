var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-3"},[_c('TButton',{staticClass:"px-4",attrs:{"content":"Create product","variant":"","size":"","color":"info","messageOptions":{ bold: true },"icon":"cilPlus","to":"products/new"}})],1),_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"product.products","resource":"products","enterable":"","reloadable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.fetch},scopedSlots:_vm._u([{key:"image_url",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SCardProductItems',{attrs:{"item":{ image_url: item.image_url, name: item.name },"widthAuto":""}})],1)]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.price,"currency":item.currency_id || 'JPY'}})],1)]}},{key:"suppliers",fn:function(ref){
var item = ref.item;
return [_c('td',[(!_vm.lodash.isEmpty(item.suppliers))?_c('TMessage',{attrs:{"content":item.suppliers[0].name,"noTranslate":""}}):_c('TMessageNotFound'),(item.suppliers.length > 1)?_c('TMessage',{attrs:{"content":"other suppliers","size":"small","color":"muted","truncate":1},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_vm._v(" +"+_vm._s(item.suppliers.length - 1)+" ")]},proxy:true}],null,true)}):_vm._e()],1)]}},{key:"origin",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.origin)?_c('TMessage',{attrs:{"content":item.origin.name,"noTranslate":""}}):_c('TMessageNotFound')],1)]}},{key:"category",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.categories && item.categories.length)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((item.categories),function(category){return _c('SMessageProductCategory',{key:category.id,staticClass:"mr-1 mb-1",attrs:{"category":category}})}),1):_c('TMessageNotFound')],1)]}},{key:"tag",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.tags && item.tags.length)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((item.tags),function(tag){return _c('CBadge',{key:tag.id,staticClass:"mr-1 mb-1",staticStyle:{"font-size":"85%"},attrs:{"color":"info"}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1):_c('TMessageNotFound')],1)]}},{key:"embargo",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.banned_sea)?_c('TMessage',{attrs:{"content":"Banned sea","color":"danger"},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cilBan"}})]},proxy:true}],null,true)}):_vm._e(),(item.banned_air)?_c('TMessage',{attrs:{"content":"Banned air","color":"danger"},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cilBan"}})]},proxy:true}],null,true)}):_vm._e(),(item.banned_export)?_c('TMessage',{attrs:{"content":"Banned export","color":"danger"},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cilBan"}})]},proxy:true}],null,true)}):_vm._e(),(item.special)?_c('TMessage',{attrs:{"content":"Special goods","color":"danger"},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cilDiamond"}})]},proxy:true}],null,true)}):_vm._e(),(item.private_license)?_c('TMessage',{attrs:{"content":"Private license","color":"danger"},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cilLibraryBooks"}})]},proxy:true}],null,true)}):_vm._e()],1)]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.updated_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id,"placeholder":"Product Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"image_url-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name,"placeholder":"Product name"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true},{key:"origin-filter",fn:function(){return [_c('SSelectProductOrigin',{attrs:{"value":_vm.filter.origin_id,"prepend":_vm.prepend},on:{"update:value":function($event){return _vm.$set(_vm.filter, "origin_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"category-filter",fn:function(){return [_c('SSelectProductCategory',{attrs:{"value":_vm.filter['categories']},on:{"update:value":function($event){return _vm.$set(_vm.filter, 'categories', $event)},"change":_vm.filterChange}})]},proxy:true},{key:"tag-filter",fn:function(){return [_c('SelectTags',{key:_vm.tagsKey,attrs:{"value":_vm.filter['tags']},on:{"update:value":function($event){return _vm.$set(_vm.filter, 'tags', $event)},"change":_vm.filterChange}})]},proxy:true},{key:"suppliers-filter",fn:function(){return [_c('SSelectSupplier',{attrs:{"value":_vm.filter['suppliers.id'],"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, 'suppliers.id', $event)},"change":_vm.filterChange}})]},proxy:true},{key:"embargo-filter",fn:function(){return [_c('SelectEmbargoFilter',{key:_vm.embargoKey,on:{"update:value":_vm.setEmbargo}})]},proxy:true},{key:"updated_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setUpdatedAtFilter]}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }